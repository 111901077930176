<template>
  <div>
    <c-table
      ref="table"
      title="연간 교육 계획 목록"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
      gridHeight="550px"
      :checkClickFlag="false"
      :isExcelDown="true"
      @linkClick="linkClick"
    >
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'annual-education-plan',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          eduEducationYearPlanId: '',
        }
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [],
        data: [],
      },
      searchParam: {
        plantCd: null,
        educationYear: '',
        educationKindCdSmall: null,
        educationKindCdLarge: null,
        educationTypeCd: null,
        legalEduYn: null,
        eduEducationYearPlanId: '',
      },
      searchYear: '',
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.edu.annual.list.url;
      // list setting
      let yearmonth = []
      for(let i = 1; i <= 12; i++) {
        let month = this.$_.padStart(String(i), 2, '0');
        yearmonth.push({
          name: 'mon' + month,
          field: 'mon' + month,
          label: i + '월',
          align: 'center',
          sortable: false,
          child: [
            {
              name: 'monplan' + month,
              field: 'monplan' + month,
              label: '계획',
              align: 'center',
              style: 'width:40px', 
              sortable: false,
              type: "link",
            },
            {
              name: 'monresult' + month,
              field: 'monresult' + month,
              label: '결과',
              align: 'center',
              style: 'width:40px', 
              sortable: false,
              type: "link",
            },
          ]
        })
      }
      const yearLabel = `${this.$language('연간교육일정')} <font class="text-negative" style="font-size:0.9em;font-weight:500;">`
        + `(✅ ${this.$language('연간교육일정으로 세운 교육계획정보가 카운트됩니다.')})</font>`;
      this.grid.columns = [
          {
          name: "plantName",
          field: "plantName",
          label: "사업장",
          style: 'width:100px',
          align: "center",
          sortable: true,
        },
        {
          name: "educationCourseName",
          field: "educationCourseName",
          label: "교육과정",
          align: "left",
          style: 'width:200px',
          sortable: true,
          type: "link",
        },
        {
          name: "educationKindCdLargeName",
          field: "educationKindCdLargeName",
          label: "교육종류1",
          style: 'width:100px',
          align: "center",
          sortable: true,
        },
        {
          name: "educationKindCdSmallName",
          field: "educationKindCdSmallName",
          label: "교육종류2",
          style: 'width:100px',
          align: "center",
          sortable: true,
        },
        {
          name: "educationTypeNames",
          field: "educationTypeNames",
          label: "교육구분",
          style: 'width:100px',
          align: "center",
          sortable: true,
        },
        {
          label: yearLabel,
          align: 'center',
          sortable: false,
          child: yearmonth
        },
        {
          name: "relatedLawsName",
          field: "relatedLawsName",
          label: "관련법규",
          style: 'width:200px',
          align: "left",
          sortable: true,
        },
        {
          name: "departmentDeptNames",
          field: "departmentDeptNames",
          label: "주관부서",
          style: 'width:120px',
          align: "left",
          sortable: true,
        },
      ]
      if (this.popupParam.eduEducationYearPlanId) {
        this.searchParam.eduEducationYearPlanId = this.popupParam.eduEducationYearPlanId
      }
      this.getList();
    },
    getList() {
      // if (!this.searchParam.educationYear) {
      //   window.getApp.$emit('ALERT', {
      //     title: '안내', // 안내
      //     message: 
      //     // item.equipmentNm + 
      //     '교육년도를 선택하세요.', // 점검년도를 선택하세요
      //     type: 'warning', // success / info / warning / error
      //   });
      //   return;
      // }
      // 전체조회
      this.searchYear = this.$_.clone(this.searchParam.educationYear);
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.annualPopup(row, col, true);
    },
    onItemClick() {
      this.annualPopup(null, null, false);
    },
    annualPopup(result, col, flag) {
      let selectData = this.$refs['table'].selected;

      let title = '';
      let param = null;

      if (!col) {
        this.popupOptions.target = () =>
          import(`${"@/pages/sop/edu/annual/annualEducationPlanDetail.vue"}`);
          param = {
            selectData: selectData ? selectData: [],
            disabled: false,
            eduEducationId: '',
            eduCourseId: '',
          };
        this.popupOptions.isFull = false;
        title = '연간교육계획 상세';

      } else if (col.name === 'educationCourseName') {
        this.popupOptions.target = () =>
          import(`${"@/pages/sop/edu/cc/educationCourseDetail.vue"}`);
          param = {
            eduCourseId: result.eduCourseId ? result.eduCourseId : '',
          };
        this.popupOptions.isFull = true;
          title = '교육과정 마스터 상세';
      } else {
        this.popupOptions.target = () =>
          import(`${"@/pages/sop/edu/annual/annualEducationPlanDetail.vue"}`);
          param = {
            eduEducationId: result.eduEducationId ? result.eduEducationId : '',
            eduCourseId: result.eduCourseId ? result.eduCourseId : '',
            educationMonth: flag ? col.name.substring(col.name.length - 2) : '',
            educationYear: flag ? result.educationYear : '',
            plantCd: flag ? result.plantCd : '',
            disabled: true,
            /**
             * 2022-05-12 kdh
             * 교육, 결과/완료 구분자 param
             */
            stepFlag: col.name.indexOf('plan') > -1 ? 'N' : 'Y'
          };
        this.popupOptions.isFull = false;
        title = result.educationCourseName + '의 ' + this.searchYear + '년 ' +
        col.label + ' 연간교육계획 상세'
      }
      this.popupOptions.title = title;
      this.popupOptions.param = param;
      this.popupOptions.width = '95%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup(type) {
      this.$refs['table'].selected = []
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (type === 'COMPLETE' || type === 'SAVE') {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
      }
      this.getList();
    },
  }
};
</script>